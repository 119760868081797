.steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .steps-content {
    width: 100%; /* Ensure content takes full width */
    padding: 20px;
  }
  
  .steps-action {
    width: 100%; /* Ensure actions take full width */
    text-align: left;
    padding: 20px;
  }
  
  @media (max-width: 200px) {
    .steps-content,
    .steps-action {
      padding: 10px; /* Reduce padding for smaller screens */
    }
  }
  .App {
    font-family: sans-serif;
    text-align: left;
  }
  
  
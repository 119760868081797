/* chartjs-plugin-zoom-custom.css */

.chartjs-plugin-zoom {
    position: relative;
    cursor: grab;
    display: block;
  }
  
  .chartjs-plugin-zoom.draggable {
    cursor: grabbing;
  }
  
  .chartjs-plugin-zoom.draggable.dragging {
    cursor: grabbing;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container {
    overflow: hidden;
    touch-action: none;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container > canvas {
    touch-action: none;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-possible {
    cursor: zoom-in;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-in {
    cursor: zoom-out;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-out {
    cursor: zoom-in;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-in.zoom-in-active,
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-out.zoom-out-active {
    cursor: crosshair;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-active {
    cursor: move;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-out.zoom-out-active {
    cursor: zoom-out;
  }
  
  .chartjs-plugin-zoom .chartjs-zoom-container.zoom-in.zoom-in-active {
    cursor: zoom-in;
  }
  
/* styles.css */
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.pagination li {
  margin: 0 5px;
}

.pagination a,
.pagination span {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid #007bff;
  color: #007bff;
  text-decoration: none;
  border-radius: 5px;
}

.pagination a:hover {
  background-color: #007bff;
  color: white;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
}

.pagination .break-me {
  padding: 10px 15px;
}

.custom-flags-select .react-flags-select {
    height: 20px; 
    
  }
  
  .custom-flags-select .react-flags-select .selected-flag {
    border: none;
  }
  
  .custom-flags-select .react-flags-select .country-label {
    color: black !important;
  }
  
  .menu-flags-button {
    border: none;
    background-color: transparent;
    padding: 0px;
  }
  
  .placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
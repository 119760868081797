

.App {
    text-align: left;
  }

  .centered-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px; /* Adjust the height as needed */
  }
.ant-dropdown-menu {
    max-height: 200px;
    overflow: auto;
    background-color: #d3dbe4;
    width: 160px;
    font-size: 15px;
    justify-content: center;
}
.buttonSim {
  background-color: #d3dbe4;
  height: 35px;
  font-size: 15px;
}
.country-label {
  font-size: 15px;
  color: rgb(10, 2, 2);
  /* font-weight: bold; */
  /* Add more styles as needed */
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: rgba(216, 73, 73, 0.2); */
    width: 100%;
    font-weight: 500;
    font-size: 15px;
  }
  
  .pagination-container a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(34, 8, 137);
    /* padding: 8px 14px; */
    height: 40px;
    width: 120px;
    text-decoration: none;
    transition: background-color .2s;
    border: 1px solid rgb(10, 2, 2);
    cursor: pointer;
    
  }
  
  .pagination-container a.active {
    background-color: #007bff;
    color: white;
    border: 1px solid #7cbddb;
  }
  
  .pagination-container a.disabled { 
    opacity: 0.2;
  }
  .pagination-container a:hover:not(.active) {background-color: rgb(238, 238, 238);}
 
.container {
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  gap: 10px; /* Optional: add space between rows */
}

.row {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}